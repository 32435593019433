.loginButton {
  background-color: white;
  color: black;
  border: 2px solid #06c8ef;
}
.header__fixed {
  position: fixed;
  width: 100%;
  top: 0;
}
.header__logo {
  width: 20%;
  border-radius: 6px;
}
