.blue {
  color: blue;
  width: 3rem;
  height: 3rem;
  border: 1px solid black;
  background-color: blue;
  margin: 8px 0 7px 0;
  border-radius: 7px;
}
.yellow {
  color: yellow;
  width: 3rem;
  height: 3rem;
  border: 1px solid black;
  background-color: yellow;
  margin: 8px 0 7px 0;
  border-radius: 7px;
}
.orange {
  color: orange;
  width: 3rem;
  height: 3rem;
  border: 1px solid black;
  background-color: orange;
  margin: 8px 0 7px 0;
  border-radius: 7px;
}
.pink {
  color: rgb(251, 5, 46);
  width: 3rem;
  height: 3rem;
  border: 1px solid black;
  background-color: rgb(251, 5, 46);
  margin: 8px 0 7px 0;
  border-radius: 7px;
}
.green {
  color: green;
  width: 3rem;
  height: 3rem;
  border: 1px solid black;
  background-color: green;
  margin: 8px 0 7px 0;
  border-radius: 7px;
}
.white {
  color: white;
  width: 3rem;
  height: 3rem;
  border: 1px solid black;
  background-color: white;
  margin: 8px 0 7px 0;
  border-radius: 7px;
}

.multi_color_divs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
h1 {
  font-size: 2em;
  font-weight: 400;
}
h4 {
  text-align: center;
}
.pipeImage {
  width: 80%;
}
.pipeImage2 {
  height: 27rem;
  width: 400px;
}

.fittingImageBend {
  height: 300px;
  width: 370px;
  margin-left: 10rem;
}
.fittingImageCoupler {
  width: 350px;
  height: 350px;
  margin-left: 12rem;
}
.hrLineOrange {
  border: 3px solid black;
}
.ourProducts {
  font-size: 3rem;
  font-weight: 600;
  text-decoration: underline;
  padding: 10px;
  text-align: center;
}
.ourProduct {
  list-style-type: none;
  text-align: center;
  padding: 10px;
  font-size: 19px;
  line-height: 3rem;
  font-weight: 400;
  margin-bottom: 4rem;
}
table {
  text-align: center;
}
